import React from "react"
import Layout from "@app-components/layout/"
import Grid from "@app-components/ui/grid/"
import Feature from "@app-components/ui/feature/"
import Header from "@app-components/ui/header/side_by_side/"
import HeaderImage from "@page-components/products/bookings/media/header/"
import WizardImage from "@page-components/products/bookings/media/wizard/"
import DeliveryImage from "@page-components/products/bookings/media/delivery/"
import HEADERS from "@data/headers/"

const Bookings = () => (
  <Layout getStartedButtons={["demo", "get-started"]} page="products/bookings">
    <Header
      title="CineSend Bookings"
      text="With just a click, deliver to over 185,200 screens at 40,200 commercial cinema locations in 100+ countries."
      image={<HeaderImage />}
      secondaryNavigation={HEADERS.bookings}
      buttons={[
        {
          text: <div className="red">Get Started</div>,
          className: "web_white",
          type: "link",
          link: {
            to: "https://accounts.cinesend.com",
            type: "external"
          }
        },
        {
          text: "Schedule a Demo",
          className: "web_white_outline ",
          type: "link",
          link: {
            to: "/demo",
            type: "internal"
          }
        }
      ]}
    />
    <Feature
      title="Campaign Wizard"
      text="Keep it simple - Pick your booking range. Pick your content. Pick your theatres. CineSend Bookings takes care of the rest. Save campaign templates to reuse later and reduce data entry. Browse theatres in territories to discover locations you may not have known about."
      icon="campaign-wizard"
      image={<WizardImage />}
    />
    <Feature
      title="Track Delivery Statuses"
      reverse
      text="View the status of all your theatrical deliveries all in one place. CineSend Bookings syncs with last-mile vendors in real time to provide you with the most up-to-date tracking information. CineSend monitors deliveries and remediates if a vendor is likely to miss a delivery."
      icon="delivery"
      image={<DeliveryImage />}
    />
    <Grid
      columnWidth={4}
      items={[
        {
          title: "Cross-Vendor Booking",
          text:
            "Spend less time monitoring and negotiating prices between delivery vendors in different markets. CineSend Bookings will pair each of your bookings with the best satellite, broadband, or terrestrial vendor in each delivery market. You’ll always get the best price.",
          _id: 1,
          icon: "vendor-white"
        },
        {
          title: "Automated KDMs",
          text: `CineSend automatically generates and delivers KDMs for encrypted content, per the booking ranges and screen restrictions you set when you launch your campaign. Need to extend a booking? No problem. Just update the booking dates and new KDMs are sent.`,
          _id: 2,
          icon: "kdm-white"
        },
        {
          title: "Customized Preferences",
          text:
            "CineSend is vendor-agnostic, but you might not be. Build rules to prefer or block certain vendors in certain territories. You can also enforce facility security requirements so that your content stays within an MPAA compliant ecosystem.",
          _id: 3,
          icon: "settings-white"
        }
      ]}
    />
  </Layout>
)

Bookings.propTypes = {}

export default Bookings
